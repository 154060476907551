.image {
    width: 100%;
    height: 100%;
}

.full {
    overflow: visible!important;
    transition: 300ms;
    transition: opacity 700ms ease 0ms;
}

.thumb {
    filter: blur(9px);
    transform: scale(1.07);
    transition: visibility 0ms ease 700ms;
    visibility: visible;
    position: absolute;
    top: 0;
}

.slick-list {
    transition-property: height;
    transition-timing-function: ease-in-out;
    transition-duration: 200ms;
}

.image-container {
    position: relative;
    overflow: hidden;
    -webkit-animation: placeholderShimmer 2s linear;
        animation: placeholderShimmer 2s linear;
    -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
    background-color: #FFFFFF;
    background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.08)), color-stop(15%, rgba(0, 0, 0, 0.15)), color-stop(30%, rgba(0, 0, 0, 0.08)));
    background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.08) 0%, rgba(0, 0, 0, 0.15) 15%, rgba(0, 0, 0, 0.08) 30%);
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.08) 0%, rgba(0, 0, 0, 0.15) 15%, rgba(0, 0, 0, 0.08) 30%);
    background-size: 1200px 100%;
}

.image-container.visible {
    overflow: visible;
}
@import "include-media/dist/_include-media.scss";

$breakpoints: (phone: 320px, tablet: 768px, desktop-small: 992px, desktop: 1024px);

// constant variables
$margin-top: 110px;

@-webkit-keyframes placeholderShimmer {
    0% {
        background-position: -1200px 0;
    }
    100% {
        background-position: 1200px 0;
    }
}
@keyframes placeholderShimmer {
    0% {
        background-position: -1200px 0;
    }
    100% {
        background-position: 1200px 0;
    }
}

.container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 975px;
    }
}

@media (min-width: 1200px) {
    .container {    
        max-width: 975px;
    }
}
@font-face {
    font-family: 'Montserrat-Light';
    font-style: normal;
    font-weight: 400;
    src: url("fonts/Montserrat-Light.ttf") format("truetype");
  }
  
  @font-face {
    font-family: 'Montserrat-Regular';
    font-style: normal;
    font-weight: 400;
    src: url("fonts/Montserrat-Regular.ttf") format("truetype");
  }
  
  @font-face {
    font-family: 'Montserrat-SemiBold';
    font-style: normal;
    font-weight: 400;
    src: url("fonts/Montserrat-SemiBold.ttf") format("truetype");
  }
  
  @font-face {
    font-family: 'Montserrat-Medium';
    font-style: normal;
    font-weight: 400;
    src: url("fonts/Montserrat-Medium.ttf") format("truetype");
  }
  
  @font-face {
    font-family: 'Montserrat-Thin';
    font-style: normal;
    font-weight: 400;
    src: url("fonts/Montserrat-Thin.ttf") format("truetype");
  }
  
@import "../variables.scss";

.profile { 
    margin-top: $margin-top;
    display: flex;
    flex-direction: column;
    width: 100%;

    @include media("<tablet") {
        margin: 0;
    }
}

.content-profile {
    max-width: 935px;
}

.profile-head {
    margin-top: 20px;
    display: flex;
    margin-bottom: 45px;


    @include media("<tablet") {
        margin-bottom: 10px;
        margin-top: 66px;
    }
}

.profile-photo {
    min-width: calc(33.333% - 20px);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    flex-direction: column;

    .profile-bio {
        display: none;

        @include media("<tablet") {
            margin-top: 10px;
            font-weight: bold;
            display: block;
        }
    }

    img {
        width: 150px;
        height: auto;
        border-radius: 100%;

        @include media("<tablet") {
            width: 90px;
            max-width: 150px;
        }
    }
}

.profile-name {
    font-size: x-large;

    @include media("<tablet") {
        display: none;
    }
}

.profile-bio {
    @include media("<tablet") {
        display: none;
    }
}

.profile-info {
    width: 75%;
    padding: 10px;   
}

.profile-stats {
    display: flex;
    margin: 20px 0;

    >*:not(:last-of-type) {
        margin-right: 20px;
    }

    @include media("<tablet") {


        h4 {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }
}

.selected-posts {
    margin: 0 10px;
    border-top: 1px solid #cecece;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    gap: 5%;
    position: sticky;
    top: 56px;
    background-color: #fafafa;
    z-index: 2200;

    @include media("<tablet") {
        border-top: none;
        margin: 0;
        margin-bottom: 2px;
        width: 100%;
        gap: 3px;

        img {
            transform: scale(1.6);
            margin-bottom: 5px;
        }
    }

    >* {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: -1px;
        padding-top: 12px;
        color:rgb(130, 130, 130);
        cursor: pointer;
        border-top: 1px solid transparent;
        filter: invert(0.4);

        @include media("<tablet") {
            width: 33.333%;
            border-top: none;
            border-bottom: 1px solid transparent;
        }

        &.active {
            color: unset;
            border-top: 1px solid black;
            filter: none;

            @include media("<tablet") {
                border-top: none;
                border-bottom: 1px solid black;
            }
        }
    }

    .select-text {
        font-size: 9pt;
        margin-left: 5px;

        @include media("<tablet") {
            display: none;
        }
    }
}

.select-reels{
    img:not(:first-of-type) {
        margin-left: -13px;
    }

    .select-text {
        margin-left: 8px;
    }

    @include media("<tablet") {
        img {
            transform: scale(1.3);
        }
    }
}        

.select-tagged{
    img:not(:first-of-type) {
        margin-left: -15px;
    }

    .select-text {
        margin-left: 8px;
    }

    @include media("<tablet") {
        img {
            transform: scale(1.3);
        }
    }
}   

.profile-posts {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 150px;

    @include media("<tablet") {
        gap: 3px;
    }
}

.profile-post {
    min-width: calc(33.333% - 20px);
    padding: 10px;

    @include media("<tablet") {
        min-width: calc(33.333% - 2px);
        padding: 0;
    }

    &:hover {
        .post-hover {
            opacity: 1;
            background-color: rgba(0, 0, 0, 0.15);
        }
    }
}

.post-hover {
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    position: absolute;
    color: #fafafa;
    top: 0;
    width: 100%;
    height: 100%;
    transition: opacity 150ms, background 500ms;
    background-color: rgba(85, 85, 85, 0);
    gap: 20px;

    >* {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    img {
        filter: invert(1);
        margin-right: 5px;
    }
}

.multiple-images {
    position: absolute;
    top: 8px;
    right: 8px;
}

.video-logo {
    position: absolute;
    top: 8px;
    right: 8px;

    img {
        filter: invert(1)
    }
}

.profile-post-image {
    display: block;
    width: 100%;
    height: auto;
    overflow: hidden;
    position: relative;
    padding-top: 100%;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}
@import "variables";

body.dark {
    background-color: black;
    color: #fafafa;

    a {
        color: #fafafa;
    }

    strong {    
        a {
            color: #fafafa;
        }
    }

    .loading-screen {
        background-color: black;
    }

    .navbar {
        background-color: black;
        border-color:#353535;

        img:not(.sidebar-head-logo) {
            filter: invert(1);
        }
    }

    .nav-search {
        filter: invert(1);
    }

    .navbar-mobile {
        background-color: black;

        img {
            filter: invert(1);
        }
    }

    .status-bar {
        background-color: black;
    }

    .status-picture-img,
    .post-picture-img, 
    .post-friend-img {
        background-color: black;
    }

    .card {
        background-color: black;
    }

    .follow {
        color: #0095f6;
    }

    .video {
        .follow {
            color: white;
        }
    }

    .card-footer {
        background-color: black;
    }

    .card-logos {
        img {
            filter: invert(1);
        }
    }

    .card-logos {
        img.heart-filled {
            filter: invert(15%) sepia(88%) saturate(7048%) hue-rotate(2deg) brightness(106%) contrast(118%);
        }
    }

    .comments-page {
        .post-title {
            @include media("<tablet") {
                border-top: 1px solid rgba(255, 255, 255, 0.125);
                border-bottom: 1px solid rgba(255, 255, 255, 0.125);
            }
        }

        .new-comment {
            img {
                filter: invert(1);
            }
        }
    }

    .post-title-text {
        color: #fafafa;
    }

    .post-comment-input {
        color: #fafafa;
    }
    
    .slick-dots li button:before {
        color: #fafafa;
    }

    .image-container {
        -webkit-animation: placeholderShimmer 2s linear;
        animation: placeholderShimmer 2s linear;
        animation-iteration-count: 1;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        background-color: #111111;
        background-image: linear-gradient(90deg, rgba(255,255,255,.03) 0,rgba(255,255,255,.07) 30%,rgba(255,255,255,.03) 60%);
        background-size: 1200px 100%;
    }

    .thumb {
        filter: blur(9px) brightness(0.8);
    }

    .selected-posts {
        border-top: 1px solid #333;
        background-color: #000;



        img {
            filter: invert(1);
        }

        >* {
            filter: invert(0.3);

            &.active {
                filter: none;
                border-top: 1px solid #ccc;

            }
        }
    }
}
@import "../variables.scss";

.card {
    position: relative;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 8px;

    @include media("<tablet") {
        border: none;
        border-radius: 0;
        width: 100vw;
    }
}

.card-post {
    margin-bottom: 15px;

    @include media("<tablet") {
        margin-bottom: 0;
    }
}

.card-header {
    padding: 8px 12px;
    margin-bottom: 0;
    border-bottom: 1px solid rgba(0,0,0,.125);

    @include media("<tablet") {
        padding: 8px;
    }

    &.video {
        border: none;
        z-index: 1000;
        color: white;

        a {
            color: white;
        }

        span {
            img {
                filter: invert(1);
            }
        }
    }
}

.rounded-circle {
    border-radius: 50%;
}

.card-body {
    padding:0;
}

.slide-video,
.video-container {
    @include media(">=tablet") {
        overflow: hidden;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
    }
}

.video-container-inner {
    overflow: hidden;
    position: relative;

    .thumb {
        height: 100%;
    }
}


.card-footer {
    background-color:rgba(255, 255, 255, 1);
    padding: 12px;
    margin-top: auto;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    z-index: 1800;
}

.card-logos {
    margin-bottom: 12px;
    display: flex;

    >img:first-of-type {
        margin-right: 15px;
    }

    img:not(:first-of-type) {
        margin-left: 15px;
    }

    img:last-of-type {
        margin-left: auto;
    }

    @include media(">=tablet") {
        margin-bottom: 20px;

        >img:first-of-type {
            margin-right: 20px;
        }

        img:not(:first-of-type) {
            margin-left: 20px;
        }
    
        img:last-of-type {
            margin-left: auto;
        }

        img {
            scale: 1.1;
        }
    }
}


.likes {
    margin-bottom: 8px;
}

.date {
    margin-top: 10px;
    font-size: 10pt; 
    
    small {
        color: rgba(153,153,153,1);
        font-weight: 400;
        margin-top: 10px;   
    }
}

.heart-filled {
    filter: invert(15%) sepia(88%) saturate(7048%) hue-rotate(2deg) brightness(106%) contrast(118%);
}

.heart-filled-image {
    position: absolute;
    top: calc(50% - 50px);
    left: calc(50% - 50px);
    transition: 500ms;
    pointer-events: none;
    opacity: 0;

    img {
        filter: invert(1);
    }
}

.post-header {
    display:flex;
    align-items:center;
    flex-direction: row;
}

.post-header-title-text {
    display:flex;
    align-items:center;
    flex-direction: row;
}

.post-header-extra {
    align-self: flex-end;
}


.post-picture {
    display: flex;
    align-items: center;
    width: 42px;
    height: 42px;
    background: linear-gradient(to top right, #ffca00, #d723cc);
}

.post-picture-location {
    display:flex;
    width:100%;
    flex-direction: column;
    flex:1;
    margin-left:16px;

    small {
        margin-top: 3px;
    }
}


.username, .username:hover {
    text-decoration: none;
    color:rgba(38,38,38,1);
}

.follow {
    font-size: 10.5pt;
    text-decoration: none;
    color: #0095f6;
}

.dot {
    margin-left: 1px;
    margin-right: 6px;
}

.post-title {
    margin-top: 5px;
}

.post-title-text {
    margin-left: 5px;
    color:rgba(38,38,38,1);

    br {
        margin: 3px 0;
    }

    a {
        cursor: pointer;
        color: #112b77;
    }
}

.too-long {
    cursor: pointer;
    color: rgb(114, 114, 114);
}

.translate {
    cursor: pointer;
}

.comments {
    display: flex;
    flex-direction: column;
    border-top: 1px solid rgba(219,219,219,1);
    padding: 10px;
    margin-top: 15px; 
    
    @include media("<tablet") {
        display: none;
    }
}

.comment {
    margin: 10px 0;
    display: flex;
    align-items: center;

    strong {
        margin-right: 5px;
    }
}

.comment-picture {
    width: 20px;
    margin-right: 5px;
}

.new-comment {
    display: flex;
    justify-content: space-between;
    color: rgb(153, 153, 153);
}

.post-comment-input {
    border: none;
    width: 100%;
    background: transparent;

    &:focus-visible {
        outline: none;
        background: transparent !important;
    }
}

.post-comment-button {
    border: none;
    background: none;
    margin-left: auto;
    color: rgba(153,153,153,1);
    padding: 0.5em;

    &:hover {
        cursor: pointer;
    }
}

.view-comments {
    color:rgb(130, 130, 130);
    margin-top: 10px;
    display: flex;
}

.post-picture-img,
.post-friend-img {
    margin-left: 2px;
    justify-content: center;
    padding: 2px;
    background-color: rgba(255, 255, 255, 1);
    vertical-align: middle;
    border-style: none;
}

.liked-by-friends {
    img:not(:first-of-type) {
        margin-left: -25px;
    }

    img:last-of-type {
        margin-right: 5px;
    }
}

.slick-dots {
    z-index: 2000; 
    bottom: -33px;
}

.slick-dots li {
    width: 10px;
}

.image-container .slick-dots li.slick-active button:before {
    color:rgb(95, 180, 255);
    opacity: 1;
    font-size: 8px;
}
  
.image-container .slick-dots li {
    margin: 0;
}

.mute-button {
    position: absolute;
    padding: 7px;
    bottom: 20px;
    right: 18px;
    display: flex;
    background-color: rgba(0,0,0,.3);
    border-radius: 100%;

    img {
        pointer-events: none;
        filter: invert(1);
    }
}

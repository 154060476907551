@import "../variables";

.comments-container {
    margin-top: 100px;

    @include media("<tablet") {
        margin-top: 58px; 
    }
}

.comments-page {
    display: flex;
    flex-direction: column;
    position: fixed;

    .comment {
        padding: 0 10px;
    }

    .post-title {
        margin-top: 0;
        padding: 10px;
        display: flex;
        align-items: center;

        @include media("<tablet") {
            border-top: 1px solid rgba(0, 0, 0, 0.125);
            border-bottom: 1px solid rgba(0, 0, 0, 0.125);
        }
    }

    .new-comment {
        padding: 0 5%;

        @include media("<tablet") {
            height: 45px;
            bottom: 58px;
            position: fixed;
            z-index: 1800;
            width: 90%;
        }

        input {
            font-size: 12pt;
        }
    }

    .post-comment-input {
        padding: 0;
        margin-left: 10px;
    }

    .post-comment-button {
        padding: 0;
    }
}

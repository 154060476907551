@import "reset";
@import "variables";
@import "fonts";

body {
    font-family: 'Arial';
    font-size: 11pt;
    line-height: 4.5mm;
    color: #262626;
    background-color:rgba(250, 250, 250, 1);
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    margin:0;
    border:0;
    padding:0;

    &::-webkit-scrollbar {
        display: none;
    }    
}

a {
    text-decoration: none;
    color: #262626;
}

.container.feed {
    display: flex;
    justify-content: center;
}

.content-wrapper{
    max-width: 820px;
    padding:0;
    margin:0;
    width:100%;
    display:flex;
    justify-content: center;
}

.content-feed {
    max-width: 470px;
    margin-right: 30px;
    padding:0;
    margin-top: 86px;

    @include media("<tablet") {
        max-width: unset;
        width:100%;
        margin-top: 56px;
        margin-right: 0;
    }
}

.status-bar {
    height: 118px;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 8px;
    margin-bottom: 15px;
    padding-left: 16px;
    overflow-x: scroll;

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    &::-webkit-scrollbar {
        display: none;
    }
      
    
    @include media("<tablet") {
        height: 85px;
        margin: 0;
        border: none;
        border-bottom: 1px solid rgba(0,0,0,.125);
        border-radius: 0;
        padding-bottom: 10px;
        padding-top: 5px;
    }
}

.status-item {
    max-width: 66px;
    margin-right: 18px;
}

.status-picture {
    display: flex;
    align-items: center;
    width: 66px;
    height: 66px;
    background: linear-gradient(to top right, #ffca00, #d723cc);
    margin-bottom: 5px;
}

.status-picture-img {
    margin-left: 2px;
    justify-content: center;
    padding: 2px;
    background-color: rgb(255, 255, 255);
    vertical-align: middle;
    border-style: none;
}

.infinite-scroll-component {
    &::-webkit-scrollbar {
        display: none;
    }    
}

.content-sidebar {
    top:110px;
    max-width: 320px;
    position: -webkit-sticky; /* for Safari */
    position: sticky !important;
    align-self: flex-start; /* <-- this is the fix */

    @include media("<=desktop-small") {
        display: none;
    }
}

.scaleup {
    display:flex;
    flex:1;
    width:100% !important;
}

.sidebar-head {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.sidebar-head-logo {
    border-radius: 28px;
}

.sidebar-head-names {
    margin-left: 16px;
    margin-top: -3px;
}

.sidebar-head-profile {
    color: #262626
}

.first-name {
    margin-top: 3px;
    color:rgb(130, 130, 130);
}

.switch {
    margin-left: auto;
    font-size: smaller;
    font-weight: bold;
    color: #0095f6;
}

.sidebar-suggestions {
    display: flex;
    flex-direction: column;
    margin-top:20px;
}

.sidebar-suggestions-header {
    width:100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:space-between;
}

.sidebar-suggestions-title{
    color: #8e8e8e;
}

.suggestions-see-all {
    color: #262626
}

.suggestions-feed {
    display:flex;
    flex-direction: column;
    margin-top:12px;
}

.suggestions-item {
    display: flex;
    flex-direction: row;
    width:100%;
    align-items: center;
    margin: 4px 0;
}

.suggestions-text {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}

.followed-by {
    margin-top: 3px;
    color:rgb(130, 130, 130);
}

.suggestions-follow {
    margin-left: auto;
    font-size: smaller;
    font-weight: bold;
    color: #0095f6;
}

.sidebar-info {
    margin-top:25px;
    color: #c7c7c7;

    a {
        color: #c7c7c7;

        &:hover {
            text-decoration: underline;
        }
    }
}

.add-comment-picture-img {
    justify-content:center;
    background-color:rgba(255, 255, 255, 1);
    margin-right:5px;
}

.doubleTap{
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -140%);
    width:40%;
    height:auto;
}


.toolbar {
    z-index:1800;
    width:100%;
    margin:0;
    padding:2px;
    padding-left:23px;
    padding-right:23px;
    display:block;
    position: fixed;
    left: 0;
    bottom: 0;
    border-top: 1px solid rgba(219,219,219,1);
    background-color:rgba(250, 250, 250, 1);
}

.this-is-the-end {
    display: flex;
    justify-content: center;
    margin-top: 56px;
    margin-bottom: 56px;

    @include media("<=tablet") {
        margin-bottom: 112px;
    }
}

strong {
    font-weight: bold;

    a {
        color: black
    }
}

small {
    font-size: smaller;
}
.loading-screen {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: white;
    z-index: 3000;
}

.loading-bottom {
    position: absolute;
    margin-top: 40%;
    bottom: 15%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    span {
        margin-bottom: 5px;
    }

    .gradient-text {
        background-color: #ffca00;
        background-image: linear-gradient(to top right,  #ffca00, #d723cc);
        background-size: 100%;
        -webkit-background-clip: text;
        -moz-background-clip: text;
        -webkit-text-fill-color: transparent; 
        -moz-text-fill-color: transparent;
    }
}

.loading-middle {
    margin-top: -120px;
}

.fade-out {
    -webkit-animation: 1.5s ease 0s normal forwards 1 fadeout;
    animation: 1.5s ease 0s normal forwards 1 fadeout;
}

.fade-out-fast {
    -webkit-animation: 1.15s ease 0s normal forwards 1 fadeout;
    animation: 1.15s ease 0s normal forwards 1 fadeout;
}

@keyframes fadeout{
    0% { opacity:1; }
    50% { opacity:1; }
    100% { 
        opacity:0; 
        pointer-events: none;
    }
}

@-webkit-keyframes fadeout{
    0% { opacity:1; }
    50% { opacity:1; }
    100% { 
        opacity:0; 
        pointer-events: none;
    }
}
@import "../variables";

.navbar {
    position: fixed; /* Set the navbar to fixed position */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color:rgba(255, 255, 255, 1);
    height: 59px;
    border-bottom: 1.4px solid;
    border-color:rgba(218,218,218,1);
    z-index: 2500;
    top: 0;

    @include media("<tablet") {
        top: unset;
        bottom: 0;
        padding: 0;
        height: 56px;
        border-bottom: none;
        border-top: 1.4px solid;
        border-color:rgba(218,218,218,1);
        background-color:rgba(255, 255, 255, 1);
    }
}

.navbar-mobile {
    display: none;
    top: 0;
    height: 56px;
    width: 90%;
    padding: 0 5%;
    background-color:rgba(255, 255, 255, 1);
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1500;

    @include media("<tablet") {
        display: flex;
    }

    .nav-left {
        display: flex;

        a {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 5px;
        }
    }

    .nav-right {
        align-self: unset;
        justify-content: flex-end;
        padding: 0;
        
        &>:not(:last-of-type) {
            margin-right: 20px;
        }
    }
}


.navbar-inside {
    display:flex;
    align-items:center;
    justify-content:space-between;
}

.navbar-content {
    width: 100%;
    padding: 0 20px;
    height: 36px;
    display: flex;
    flex-direction: row;
    align-items:center;
    justify-content: center;

    @include media("<tablet") {
        height: unset;
        padding: 0;
    }
}

.nav-left {
    display:flex;
    align-items:center;
    flex-direction: row;
    justify-content:flex-start;
    align-self: flex-start;
    margin-top: 2px;
    flex: 1 0 127px;


    @include media("<tablet") {
        display: none;
    }
}

.more-logo {
    margin-top: -9px;
}

.logo-seperation {
    height:30px;
    border-left: 1px solid black;
}

.nav-center {
    display:flex;
    justify-content:center;
    align-items: center;
    flex-direction: row;
    min-width: 125px;
    flex: 0 1 auto;

    @include media("<tablet") {
        display: none;
    }
}

.nav-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1 0 127px;

    @include media(">tablet") {
        >*:not(:first-child) {
            margin-left: 22px;
        }

        .mobile {
            display: none;
        }
    }

    @include media("<=tablet") {
        width: 100%;
        padding: 0 24px;
        justify-content: space-between;

        .desktop {
            display: none;
        }
    }

    a {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.nav-search {
    border-radius:6px;
    background-color:rgba(239, 239, 239, 1);
    color:white;
    width: 238px;
    height:36px;
    display:flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    padding: 0 15px;
}

.search-logo {
    font-size:15px;
    color: rgba(153,153,153,1);
    margin-right: 10px;
    margin-top: 2px;
}

.search-text {
    color: rgba(153,153,153,1);
    margin-right:30px;
    font-size: 12pt;
    background: none;
    border: none;
    outline: none;
}

